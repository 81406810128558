import React from "react";

const UkBody = () => {
  return (
    <div className="study-sec">
      <div className="container">
        <h2>Study in UK:</h2>
        <div>
          Pursuing higher education in the UK is an exciting and rewarding
          opportunity for students around the world. Renowned for its
          prestigious universities and academic excellence, the UK offers a
          diverse range of courses and programs to suit every interest and
          career aspiration. Whether it's studying at renowned institutions such
          as Oxford or Cambridge or exploring the vibrant cultural scene in
          cities like London, Edinburgh, or Manchester, the UK provides a rich
          and immersive learning experience. Additionally, the UK boasts a
          strong emphasis on research and innovation, with many universities at
          the forefront of ground-breaking discoveries and advancements in
          various fields.
        </div>
        <div>
          Moreover, studying in the UK equips students with transferable skills,
          critical thinking abilities, and global perspectives, making them
          highly sought after by employers worldwide. With a supportive and
          inclusive environment, a commitment to high-quality education, and
          numerous scholarship opportunities, the UK remains a top choice for
          students looking to pursue higher studies.
        </div>
      </div>
    </div>
  );
};

export default UkBody;
