import React from "react";
import ladyStnt from "../../../assets/images/logo/logo.png"
const StudyAbroadSec = () => {
  return (
    <div id="study-abroad">
      <div className="container ">
        <h1 className="text-center my-5">Study Abroad Agency Bangladesh</h1>
        <div>
          Welcome to Global NetWork PVT, a leading student counseling firm
          committed to empowering Bangladeshi students with comprehensive
          educational consultancy services. With a strong presence in Sylhet,
          Bangladesh, we have been guiding students on their study abroad
          journeys for over 12+ years. Since our establishment in 2008, we have
          successfully assisted countless students in securing admission to
          top-ranked global universities, equipped with the right set of
          scholarships and financial aid. Our unwavering dedication to
          excellence in the foreign admission process has earned us the esteemed
          Best Educational Consultant Award. Additionally, our registration with
          the Bangladesh government and our outstanding track record stand as
          undeniable proof of our expertise in identifying and facilitating
          admissions to the best universities worldwide. As your trusted
          partner, we have established collaborations with 300+ renowned
          universities across the United Kingdom, China, the United States of
          America, Canada, Australia, Singapore, Italy, Ireland, Germany,
          Sweden, Finland, South Africa, Cyprus, Malaysia, India, Romania, as
          well as other European and Asian countries. This extensive network
          ensures that we can provide you with a wide range of options tailored
          to your academic aspirations, preferences, and future career goals.
        </div>
        <br />
        <div className="row">
        <div className="col-md-4">
          <img src={ladyStnt}  alt="image" style={{width:"50%"}}  class="mx-auto d-block"/>
        </div>
          <div className="col-md-8">
            At Global NetWork PVT, we understand that you are a big dreamer,
            eager to obtain a higher degree from abroad. We are here to turn
            your dreams into reality. Our team of experienced and knowledgeable
            counselors is dedicated to assisting you at every step of your
            educational journey. From helping you select the right university to
            preparing your applications, guiding you in securing scholarships
            and financial aid, and providing comprehensive visa support, we are
            committed to your success. We recognize the importance of making
            informed decisions about your future, and our personalized approach
            ensures that we take into account your unique circumstances and
            aspirations. We are here to provide you with the guidance and
            support you need to navigate the complexities of studying abroad and
            to help you seize the countless opportunities that await you. Join
            hands with Global NetWork PVT, and let us help you unlock your
            potential and achieve your academic goals.
          </div>
          {/* <div className="col-md-4"> 
        <iframe width="353" height="200" src="https://www.youtube.com/embed/4xjJI3tHfhk" title="বিদেশে উচ্চশিক্ষার পূর্বপ্রস্তুতি | Six steps to prepare for higher study abroad | KKS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default StudyAbroadSec;
