import React from "react";

const MostDesireCourses = () => {
  return (
    <div className=" container most-desired-courses">
      <span className="smallletter-text-header text-center">
        PROGRAM SEARCH
      </span>
      <h2 className="text-center">
        Most Desired Courses / Degrees for Higher Education
      </h2>
      <div className="row">
        <div className="col-md-4">
          <div className="desire-courses">Undergraduate</div>
        </div>
        <div className="col-md-4 ">
          <div className="desire-courses">PostGraduate</div>
        </div>
        <div className="col-md-4">
          <div className="desire-courses">Foundation Courses</div>
        </div>
        <div className="col-md-4 ">
          {" "}
          <div className="desire-courses">Degree Apprenticeships</div>
        </div>
        <div className="col-md-4">
          {" "}
          <div className="desire-courses">College</div>
        </div>
        <div className="col-md-4 ">
          {" "}
          <div className="desire-courses">Diploma Courses</div>{" "}
        </div>
      </div>
    </div>
  );
};

export default MostDesireCourses;
