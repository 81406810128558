import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import admission from "../../../assets/images/countries/universityAdmission.png";
import visa from "../../../assets/images/countries/visa.png";
import travelGuide from "../../../assets/images/countries/travel-guide.png";
const TrustedSurvices = () => {
  return (
    <div className="container trusted-survices">
      <span className="smallletter-text-header text-center">
        TRUSTED SERVICES
      </span>
      <h2 className="text-center">
        Application, Admission and Visa Processing
      </h2>
      <CardGroup>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={admission} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="studyAbroad/unitedKingdom">
              <small className="text-white">
                University Admission
                <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={visa} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="studyAbroad/china">
              <small className="text-white">
                Visa Processing <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={travelGuide} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="studyAbroad/usa">
              <small className="text-white ">
                Travel Guideline
                <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
      </CardGroup>
    </div>
  );
};

export default TrustedSurvices;
