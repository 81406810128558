import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/logo/colorful.png";
const Footer = () => {
  return (
    <footer className=" text-light footer">
      <Container>
        <Row>
          <Col md={2}>
            <img src={footerLogo} />
          </Col>
          <Col md={4}>
            {/* <h5>About Global Network PVT</h5> */}

            <p>
              Global Network PVT is a leading student counseling firm based in
              Sylhet, Bangladesh. We specialize in providing educational
              consultancy services to Bangladeshi students seeking to study
              abroad.
            </p>
          </Col>
          <Col md={2}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/profile">Profile</Link>
              </li>
              <li>
                <Link to="/courses/engineering">Courses</Link>
              </li>
              <li>
                <Link to="/about/contactUs">Contact Us</Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Contact Us</h5>
            <p>
              POINT VIEW SHOPPING CENTRE, 1ST FLOOR . 24-25, AMBERKHANA, SYLHET, BANGLADESH.
              <br />
              CONTACT : +8801885333299 , +8801960025611
              <br />
              EMAIL: info@globalnetworkpvt.com
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="text-center">
              &copy; {new Date().getFullYear()} Global Network PVT. All Rights
              Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
