import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import blockchain from "../../../assets/images/countries/blockchain.png";
import ielts from "../../../assets/images/countries/ielts.png";
import ai from "../../../assets/images/countries/ai.png";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
const PopularCourses = () => {
  return (
    <div className="container popular-courses">
      <span className="smallletter-text-header text-center">
        BROWSE BY DISCIPLINE
      </span>
      <h2 className="text-center">
        Most Popular Courses among Bangladeshi Students
      </h2>
      <CardGroup>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={blockchain} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="courses/blockChain">
              <small className="text-white">
                Blockchain
                <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={ielts} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="courses/ielts">
              <small className="text-white">
                IELTS <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
        <Card className="p-2 top-countries-Card">
          <Card.Img variant="top" src={ai} />

          <Card.Footer className="trusted-survice-Card-footer">
            <Link to="courses/ai">
              <small className="text-white ">
                Ai and machine learning
                <AiOutlineArrowRight />{" "}
              </small>
            </Link>
          </Card.Footer>
        </Card>
      </CardGroup>
    </div>
  );
};

export default PopularCourses;
