import React from 'react';
import CommonBanner from '../../../components/shared/commonBanner/CommonBanner';
import coursesBanner from "../../../assets/images/commonBanner/coursesBAnner.png"
import IeltsBody from './IeltsBody';
const Ielts = () => {
    return (
        <div>
            <CommonBanner headerBanner={coursesBanner} title="IELTS"/>
            <IeltsBody/>
        </div>
    );
};

export default Ielts;