import React from 'react';
import CommonBanner from '../../../components/shared/commonBanner/CommonBanner';
import coursesBanner from "../../../assets/images/commonBanner/coursesBAnner.png"
import AiBody from './AiBody';
const AI = () => {
    return (
        <div>
            <CommonBanner headerBanner={coursesBanner} title="AI And Machine Learning"/>
            <AiBody/>
        </div>
    );
};

export default AI;