import React from "react";

const ChinaBody = () => {
  return (
    <div className="study-sec">
      <div className="container">
        <h2>Study in China:</h2>
        <div>
          Higher study in China refers to pursuing an undergraduate,
          postgraduate, or doctoral degree at a university or institution in
          China. China has become a popular destination for international
          students due to its growing reputation for quality education,
          affordable tuition fees, and diverse cultural experience.
        </div>
        <div>
          To study in China, international students usually need to meet certain
          requirements, such as having a high school diploma for undergraduate
          programs or a bachelor's degree for postgraduate programs. They may
          also be required to provide proof of proficiency in the Chinese
          language (often through the HSK test) or English language (through
          tests such as IELTS or TOEFL).
        </div>
        <div>
          China offers a wide range of academic programs and fields of study,
          including engineering, business, economics, medicine, humanities, and
          social sciences. The country has several prestigious universities,
          such as Peking University, Tsinghua University, Fudan University, and
          Shanghai Jiao Tong University, which attract a large number of
          international students.
        </div>
        <div>
          To apply for higher study in China, international students typically
          need to submit an application directly to the university or through
          the Chinese government's scholarship programs, such as the Chinese
          Government Scholarship (CSC) or provincial scholarships. The
          application process usually includes submitting academic transcripts,
          recommendation letters, a statement of purpose, and sometimes, a
          personal interview.
        </div>
        <div>
          Studying in China can provide students with valuable international
          exposure, access to cutting-edge research and technology, and
          opportunities for cultural exchange. Additionally, the cost of living
          and tuition fees in China are generally lower compared to other
          popular study abroad destinations, making it an attractive option for
          many aspiring students.
        </div>
        <div>
          However, it is important for international students to research and
          understand the specific requirements, cultural differences, and
          potential language barriers associated with studying in China before
          making a decision.
        </div>
      </div>
    </div>
  );
};

export default ChinaBody;
