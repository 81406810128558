import React from "react";
import CommonBanner from "../../components/shared/commonBanner/CommonBanner";
import profileBannerImg from "../../assets/images/commonBanner/profileBanner.png";
const OurTeam = () => {
  return (
    <div>
      <div>
        <CommonBanner headerBanner={profileBannerImg} title="Our Team" />
        <div className="study-sec">
          <div className="container">
            <h2>Our Team:</h2>
            <div>
              At Student Counseling Firm, our team is dedicated to providing the
              best support and guidance for students facing various challenges.
              We understand the pressures and stress that students often
              encounter during their academic journey, and we are here to help
              them navigate through these difficulties.
            </div>
            <div>
              {" "}
              Our team consists of highly trained and experienced counselors who
              specialize in areas such as academic concerns, career planning,
              mental health, and personal development. We believe in a holistic
              approach, tailoring our counseling services to meet the unique
              needs of each student. Whether it's helping with academic
              planning, building resilience, or enhancing interpersonal skills,
              our team is committed to empowering students to thrive and succeed
              in all aspects of their lives.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
