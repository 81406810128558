import React, { useState } from "react";
import { Button, Image, NavDropdown } from "react-bootstrap";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import navLogo from "../../assets/images/logo/logoSmall.png"

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  //   const match = useMatch();
  //   console.log(match.path)
  const navigate = useNavigate();
  const goHome=()=>{
    navigate("/",)
  }
  return (
    <div>
      <nav className="main-nav">
        {/* 1st logo part */}
        <div className="logo" onClick={goHome}>
          {/* <Image alt="image" onClick={goHome} src={navLogo}></Image> */}
          <h3 >GLOBAL NETWORK Pvt Ltd</h3>
        </div>
        {/* 2nd menu part  */}
       
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <NavDropdown
                title="ABOUT US"
                className="navbarScrollingDropdown "
              >
                <p
                //   className={
                //     router.pathname == "/about/whoWeAre" ? "activeu" : ""
                //   }
                >
                  <Link to="/about/profile"> PROFILE </Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/about/ourValues" ? "activeu" : ""
                //   }
                >
                  <Link to="/about/missionVision">MISSION & VISION</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/about/ourTeam" ? "activeu" : ""
                //   }
                >
                  <Link to="/about/ourTeam">OUR TEAM</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/about/membership" ? "activeu" : ""
                //   }
                >
                  <Link to="/about/contactUs">CONTACT US</Link>
                </p>
              </NavDropdown>
            </li>
            {/* <li className={router.pathname == "/products" ? "activeu" : ""}>
              <Link to="/products">PRODUCTS</Link>
            </li> */}

            <li>
              <NavDropdown title="COURSES" className="navbarScrollingDropdown ">
                <p
                //   className={
                //     router.pathname == "/projects/manufacturing"
                //       ? "activeu"
                //       : ""
                //   }
                >
                  <Link to="/courses/blockChain"> BLOCKCHAIN</Link>
                </p>
                <p
                //   className={
                //     router.pathname == "/projects/cattle" ? "activeu" : ""
                //   }
                >
                  <Link to="/courses/ielts">IELTS</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/projects/farming" ? "activeu" : ""
                //   }
                >
                  <Link to="/courses/ai">AI & MACHINE LEARNING</Link>
                </p>

               

                
              </NavDropdown>
            </li>

            <li>
              <NavDropdown
                title="STUDY ABROAD"
                className="navbarScrollingDropdown "
              >
                <p
                //   className={
                //     router.pathname == "/sustainability/strategy"
                //       ? "activeu"
                //       : ""
                //   }
                >
                  <Link to="/studyAbroad/unitedKingdom"> STUDY IN UK</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/sustainability/economical"
                //       ? "activeu"
                //       : ""
                //   }
                >
                  <Link to="/studyAbroad/china">STUDY IN CHINA</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/sustainability/social" ? "activeu" : ""
                //   }
                >
                  <Link to="/studyAbroad/usa">STUDY IN USA</Link>
                </p>

                <p
                //   className={
                //     router.pathname == "/sustainability/environmental"
                //       ? "activeu"
                //       : ""
                //   }
                >
                  <Link to="/studyAbroad/canada">STUDY IN CANADA</Link>
                </p>
                <p
                //   className={
                //     router.pathname == "/sustainability/csrActivities"
                //       ? "activeu"
                //       : ""
                //   }
                >
                  <Link to="/studyAbroad/australia">STUDY IN AUSTRALIA</Link>
                </p>
              </NavDropdown>
            </li>
            {/* <li className={router.pathname == "/career" ? "activeu" : ""}>
              <Link to="/career">CAREER</Link>
            </li> */}
            {/* <li className={router.pathname == "/contact" ? "activeu" : ""}>
              <Link to="/contact">CONTACT</Link>
            </li> */}
          </ul>
        </div>

        <div className="social-media">
          <div className="hamburger-menu">
            <a to="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
