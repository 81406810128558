import React from "react";
import Header from "./components/navbar/Header";
import "./assets/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/home/Home";

import MissionVision from "./pages/aboutUs/MissionVision";
import Profile from "./pages/aboutUs/Profile";
import PageNotFound from "./components/NotFoundPage/PageNotFound";
import OurTeam from "./pages/aboutUs/OurTeam";
import ContactUs from "./pages/aboutUs/ContactUs";
import Footer from "./components/footer/Footer";
import BlockChain from "./pages/courses/blockChain/BlockChain";
import Ielts from "./pages/courses/ielts/Ielts";
import AI from "./pages/courses/ai/AI";
import Uk from "./pages/stydyAbroad/unitedKingdom/Uk";
import China from "./pages/stydyAbroad/china/China";
import Usa from "./pages/stydyAbroad/usa/Usa";
import Canada from "./pages/stydyAbroad/canada/Canada";
import Australia from "./pages/stydyAbroad/australia/Australia";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="courses">
            <Route path="blockChain" element={<BlockChain />} />
            <Route path="ielts" element={<Ielts />} />
            <Route path="ai" element={<AI />} />
          </Route>
          <Route path="about">
            <Route path="profile" element={<Profile />} />
            <Route path="missionVision" element={<MissionVision />} />
            <Route path="ourTeam" element={<OurTeam />} />
            <Route path="contactUs" element={<ContactUs />} />
          </Route>
          <Route path="studyAbroad">
            <Route path="unitedKingdom" element={<Uk />} />
            <Route path="china" element={<China/>}/>
            <Route path="usa" element={<Usa/>}/>
            <Route path="canada" element={<Canada/>}/>
            <Route path="australia" element={<Australia/>}/>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
