import React from 'react';
import CommonBanner from '../../../components/shared/commonBanner/CommonBanner';
import CountryBanner from "../../../assets/images/commonBanner/countryBanner.png"
import UkBody from './UkBody';
const Uk = () => {
    return (
        <div>
          <CommonBanner headerBanner={CountryBanner} title="Study in United Kingdom"/>
          <UkBody/>
        </div>
    );
};

export default Uk;