import React from 'react';
import profileBannerImg from "../../assets/images/commonBanner/profileBanner.png"
import CommonBanner from '../../components/shared/commonBanner/CommonBanner';

const ContactUs = () => {
    return (
        <>
        <CommonBanner headerBanner={profileBannerImg} title='Contact US'/>
            <div className="container" >
                <h2>Contact Us</h2>
                <div>Our contact information is available on our website, and we encourage you to reach out to us if you have any questions, concerns, or feedback. We value your input and are here to assist you in any way we can.</div>
            </div>
        </>
    );
};

export default ContactUs;