import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import firstImg from "../../../assets/images/banner1.png"
import secondImg from "../../../assets/images/banner2.png"
import thirdImg from "../../../assets/images/banner3.png"
const Slider = () => {
    return (
        <Swiper
        // spaceBetween={30}
        // centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper "
        // slidesPerView={3}
      >
       
        <SwiperSlide className="swiperCustom">
          {" "}
          <img src={firstImg} alt="image" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img src={secondImg} alt="image" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={thirdImg} alt="image" />
        </SwiperSlide>
        <SwiperSlide className="swiperCustom">
          {" "}
          <img src={firstImg} alt="image" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img src={secondImg} alt="image" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={thirdImg} alt="image" />
        </SwiperSlide>
      </Swiper>
    );
};

export default Slider;