import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import {AiOutlineArrowRight} from "react-icons/ai"
import australia from "../../../assets/images/countries/australia.png"
import uk from "../../../assets/images/countries/united-kingdom.png"
import canada from "../../../assets/images/countries/canada.png"
import china from "../../../assets/images/countries/china.png"
import usa from "../../../assets/images/countries/usa.png"
import { Link } from "react-router-dom";

const TopCountriesCards = () => {
  return (
    <div id="top-countries-sec">
      <div className="container">
        <h2>
          Students Favorite Top Countries for Higher Education from Bangladesh
        </h2>
        <div>
          These are the best places for you as a Bangladeshi student to move in,
          study and plan a secure career. Explore each option to get a detailed
          overview.
        </div>
        <CardGroup>
          <Card  className="p-2 top-countries-Card">
            <Card.Img variant="top" src={uk} />
            
            <Card.Footer className="top-countries-Card-footer">
              <Link to="studyAbroad/unitedKingdom"><small className="text-muted">Study in UK <AiOutlineArrowRight/> </small></Link>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src={china} />
            
            <Card.Footer className="top-countries-Card-footer">
              <Link to="studyAbroad/china"><small className="text-muted">Study in China<AiOutlineArrowRight/> </small></Link>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src={usa} />
            
            <Card.Footer className="top-countries-Card-footer">
              <Link to="studyAbroad/usa"><small className="text-muted">Study in USA<AiOutlineArrowRight/> </small></Link>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src={canada} />
            
            <Card.Footer className="top-countries-Card-footer">
              <Link to="studyAbroad/canada"><small className="text-muted">Study in Canada<AiOutlineArrowRight/> </small></Link>
            </Card.Footer>
          </Card>
          
        </CardGroup>
        <CardGroup>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src={australia} />
            
            <Card.Footer className="top-countries-Card-footer">
              <Link to="studyAbroad/australia"><small className="text-muted">Study in Australia <AiOutlineArrowRight/> </small></Link>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src="https://www.sangenbd.com/images/study-china-from-bd.jpg" />
            
            <Card.Footer>
              <small className="text-muted">Study in Country <AiOutlineArrowRight/> </small>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src="https://www.sangenbd.com/images/study-china-from-bd.jpg" />
            
            <Card.Footer>
              <small className="text-muted">Study in Country <AiOutlineArrowRight/> </small>
            </Card.Footer>
          </Card>
          <Card className="p-2 top-countries-Card">
            <Card.Img variant="top" src="https://www.sangenbd.com/images/study-china-from-bd.jpg" />
            
            <Card.Footer>
              <small className="text-muted">Study in Country <AiOutlineArrowRight/> </small>
            </Card.Footer>
          </Card>
          
        </CardGroup>
      </div>
    </div>
  );
};

export default TopCountriesCards;
