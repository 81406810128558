import React from 'react';
import Slider from './parts/Slider';
import StudyAbroadSec from './parts/StudyAbroadSec';
import TopCountriesCards from './parts/TopCountriesCards';
import MostDesireCourses from './parts/MostDesireCourses';
import TrustedSurvices from './parts/TrustedSurvices';
import PopularCourses from './parts/PopularCourses';

const Home = () => {
    return (
        <div>
           <Slider/>
           <StudyAbroadSec/>
           <TopCountriesCards/>
           <PopularCourses/>
           <MostDesireCourses/>
           <TrustedSurvices/>
        </div>
    );
};

export default Home;