import React from "react";
import CommonBanner from "../../components/shared/commonBanner/CommonBanner";
import profileBannerImg from "../../assets/images/commonBanner/profileBanner.png";
import successImg from "../../assets/images/others/success.jpg";

const Profile = () => {
  return (
    <div>
      <CommonBanner headerBanner={profileBannerImg} title="Our Profile" />
      <div className="container ">
        <div style={{ textAlign: "justify", marginBottom: "50px" }}>
          <h2>Our Values</h2>
          As an experienced education consultancy firm, we maintain direct
          connections with premier colleges and universities worldwide. This
          ensures that our clients encounter no delays or complications when it
          comes to securing their offer letters and Certificates of Enrollment
          (COE). We streamline the application process, ensuring a smooth and
          efficient journey for our students. We take pride in our extensive
          network of prestigious educational institutions, enabling us to offer
          a diverse range of educational opportunities to our students. Our
          strong relationships with these universities and colleges allow us to
          provide accurate and up-to-date information regarding admission
          requirements, scholarship programs, and available courses. At Global
          Network PVT, we understand that each student is unique, with distinct
          educational goals and aspirations. We take a personalized approach,
          tailoring our services to meet the individual needs of our students.
          Our experienced consultants work closely with each student, providing
          guidance and support at every step of the educational journey. From
          university selection to application assistance and visa support, we
          are dedicated to ensuring a seamless and successful study abroad
          experience. We strive to create a positive and nurturing environment,
          where students feel empowered to pursue their dreams and achieve
          academic excellence. Our commitment to transparency, integrity, and
          professionalism sets us apart, earning the trust and satisfaction of
          our clients. Join Global Network PVT today and embark on a journey of
          educational discovery and growth. Let us be your trusted partner in
          shaping a brighter future through the power of education.
        </div>
        <h3>Core Values</h3>
        <ul>
          <li>
            Excellence: We strive for excellence in all aspects of our
            educational offerings. We encourage and support our students,
            faculty, and staff to pursue excellence in their academic endeavors.
          </li>
          <li>
            Integrity: We maintain the highest standards of integrity, honesty,
            and ethical conduct. We foster an environment that values
            transparency and accountability, ensuring that our actions align
            with our values.
          </li>
          <li>
            Student-Centric Approach: We place our students at the center of
            everything we do. Our programs and services are designed to address
            their unique needs and aspirations, empowering them with the
            knowledge, skills, and networks necessary to succeed in a rapidly
            evolving global landscape.
          </li>
          <li>
            Diversity and Inclusivity: We celebrate and embrace diversity in all
            its forms. We strive to create an inclusive learning environment
            that respects and values individuals from different backgrounds,
            cultures, and perspectives.
          </li>
          <li>
            {" "}
            Innovation: We foster a culture of innovation and creativity,
            encouraging our students and staff to think critically, solve
            complex problems, and explore new ideas. We embrace emerging
            technologies and pedagogical approaches to enhance the learning
            experience.
          </li>
          <li>
            Collaboration: We believe in the power of collaboration. We actively
            seek partnerships with universities, research institutions, and
            industry leaders to foster interdisciplinary learning, research, and
            innovation.
          </li>
        </ul>
        <div className="row">
          <span className="smallletter-text-header text-center">
            EDUCATION FOR LIGHT
          </span>
          <h2 className="text-center header-Text">Our Success Story</h2>
          <div style={{ textAlign: "justify" }}>
            Every year, we assist more than 200 students for their desired
            universities. We have expanded our services to one-to-one career
            counseling as well as choosing and selecting courses and
            universities for higher studies. We arrange resident coordinators in
            the foreign university campuses to support our students’ day-to-day
            needs.
          </div>

          <div className="col-md-7">
            At Global Network PVT, we go above and beyond to ensure that our
            students have a seamless and successful study abroad experience. Our
            services extend beyond university admissions, as we provide
            comprehensive support throughout the entire process. We understand
            the challenges and complexities students may face when studying
            abroad, and we are here to simplify and streamline the journey. In
            addition to assisting with university applications, we meticulously
            analyze and sort out the specific requirements for each application,
            helping our students navigate credit transfers and exceptions. We
            understand the importance of a smooth academic transition, and we
            work closely with universities to ensure a hassle-free credit
            transfer process. We recognize that obtaining a student visa can be
            a daunting task. That's why we offer expert guidance and assistance
            in visa applications, ensuring that our students have all the
            necessary documentation and information to maximize their chances of
            success. Furthermore, we understand the importance of comfortable
            accommodation in a foreign setting. Through our network of trusted
            partners, we can help arrange suitable accommodations for our
            students, alleviating the stress of finding housing in an unfamiliar
            environment. At Global Network PVT, we firmly believe that when
            students feel at ease and comfortable in their new surroundings,
            their confidence soars, enabling them to excel in their competitive
            degree programs. We serve as an effective bridge between the student
            and the university, providing ongoing support and guidance
            throughout their academic journey.
          </div>
          <div className="col-md-5 ">
            <img className="img-fluid" src={successImg} alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
