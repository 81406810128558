import React from "react";

const IeltsBody = () => {
  return (
    <div className="courses">
      <div className="container">
        <h2>IELTS preparation course:</h2>
        <div>
          Preparing for the International English Language Testing System
          (IELTS) requires a systematic and comprehensive approach. The IELTS
          preparation course aims to equip students with the necessary skills
          and strategies to excel in all four components of the test: listening,
          reading, writing, and speaking. The course provides guidance on
          improving language proficiency, familiarizing students with the test
          format, and practicing with authentic IELTS materials.
        </div>
        <div>
          It focuses on enhancing vocabulary, grammar, and pronunciation, as
          well as developing effective reading and listening strategies. Writing
          and speaking tasks are extensively practiced, with feedback and
          guidance from experienced instructors. The course also includes mock
          tests to simulate the actual test conditions, enabling students to
          manage time effectively and build confidence. By undergoing an IELTS
          preparation course, students can boost their chances of achieving
          their desired band score and successfully pursuing their academic or
          professional goals.
        </div>
      </div>
    </div>
  );
};

export default IeltsBody;
