import React from "react";
import CommonBanner from "../../components/shared/commonBanner/CommonBanner";
import profileBannerImg from "../../assets/images/commonBanner/profileBanner.png";

const MissionVision = () => {
  return (
    <div>
      <CommonBanner headerBanner={profileBannerImg} title="Mission & Vision" />

      <div className="study-sec">
        <div className="container">
          <h2>Mission</h2>
          <div>
            Our mission is to provide a comprehensive and transformative
            educational experience to students seeking higher studies,
            empowering them to become global leaders and change-makers in their
            respective fields. We strive to create a dynamic and inclusive
            learning environment that fosters intellectual curiosity, critical
            thinking, and personal growth.
          </div>
          <div>
            At our higher study firm, we are committed to offering a wide range
            of high-quality programs and services that meet the diverse needs
            and aspirations of students. By collaborating with esteemed
            universities and educational institutions around the world, we aim
            to provide access to educational opportunities of international
            standards.
          </div>
          <h2>Vision:</h2>
          <div>
            Our vision is to become a leading higher study firm globally,
            renowned for its excellence in education, research, and innovation.
            We seek to be a catalyst for societal progress by producing
            graduates who are intellectually capable, morally upright, and
            socially responsible.
          </div>
          <h3>Strategic Objectives:</h3>
          <div>
            <ul>
              <li>
                Academic Excellence: We aim to offer a wide range of
                high-quality academic programs that are relevant to today's
                global challenges. We strive to attract and retain exceptional
                faculty members who are dedicated to delivering world-class
                education and research.
              </li>
              <li>
                Student Success and Well-being: We are committed to providing
                comprehensive support services to ensure the success and
                well-being of our students. We offer mentorship programs, career
                guidance, counseling services, and extracurricular activities to
                foster their personal and professional growth.
              </li>
              <li>
                Research and Innovation: We encourage and support faculty and
                students in conducting cutting-edge research and innovation
                across various disciplines. We promote interdisciplinary
                collaboration and provide funding opportunities for research
                projects that have a positive societal impact.
              </li>
              <li>
                Global Engagement: We believe in the importance of global
                engagement and cultural exchange in today's interconnected
                world. We strive to provide international study opportunities,
                exchange programs, and partnerships with universities around the
                globe to broaden students' horizons and prepare them for global
                citizenship.
              </li>
              <li>
                Alumni Network and Impact: We recognize the importance of our
                alumni in contributing to societal progress. We aim to build a
                strong and active alumni network that fosters lifelong
                connections and provides opportunities for professional growth,
                mentorship, and philanthropic involvement.
              </li>
            </ul>
          </div>
          <h3>Conclusion:</h3>
          <div>
            In conclusion, our higher study firm's mission is to provide a
            holistic educational experience that not only equips students with
            knowledge and skills but also nurtures their personal and
            professional development. Through our commitment to excellence,
            diversity, innovation, and collaboration, we aspire to make a
            positive impact on our students, society, and the world at large.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
