import React from "react";

const CanadaBody = () => {
  return (
    <div className="study-sec">
      <div className="container">
        <h2>Study in Canada:</h2>
        <div>
          Embarking on a journey of higher education in Canada opens up a world
          of possibilities for students seeking academic excellence, cultural
          diversity, and career opportunities. Renowned for its top-ranking
          universities and colleges, Canada offers an exceptional education
          system that prioritizes research, innovation, and hands-on learning.
          With a wide range of programs and disciplines to choose from, students
          can explore their passions and interests while preparing for a
          successful career in their chosen field.{" "}
        </div>
        <div>
          Moreover, Canada's welcoming and inclusive environment ensures that
          students from all backgrounds feel valued and supported during their
          studies. In addition to its exceptional academic offerings, Canada
          boasts breathtaking natural landscapes, vibrant cities, and a rich
          cultural heritage, providing students with an enriching and
          well-rounded experience. Furthermore, the country's strong ties to
          industry and ample job opportunities create a conducive environment
          for students to gain practical experience and pave the way towards a
          promising future. With a commitment to education, a multicultural
          community, and a high standard of living, Canada remains a top
          destination for students seeking a well-rounded and fulfilling study
          abroad experience.
        </div>
      </div>
    </div>
  );
};

export default CanadaBody;
