import React from 'react';
import CommonBanner from '../../../components/shared/commonBanner/CommonBanner';
import CountryBanner from "../../../assets/images/commonBanner/countryBanner.png"
import AustraliaBody from './AustraliaBody';
const Australia = () => {
    return (
        <div>
            <CommonBanner headerBanner={CountryBanner} title="Study in Australia"/>
            <AustraliaBody/>
        </div>
    );
};

export default Australia;