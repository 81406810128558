import React from 'react';
import CommonBanner from '../../../components/shared/commonBanner/CommonBanner';
import CountryBanner from "../../../assets/images/commonBanner/countryBanner.png"
import UsaBody from './UsaBody';
const Usa = () => {
    return (
        <div>
           <CommonBanner headerBanner={CountryBanner} title="Study in USA"/>
           <UsaBody/>
        </div>
    );
};

export default Usa;