import React from 'react';

const BlocChainBody = () => {
    return (
        <div className='courses'>
            <div className="container">
                <h2>Blockchain:</h2>
                <div>Blockchain technology is considered a revolutionary innovation that has the potential to transform various industries. At its core, blockchain is a decentralized and distributed ledger system that allows for secure and transparent transactions. It eliminates the need for intermediaries and central authorities, making it more efficient and cost-effective. By storing data on a network of computers rather than a single server, blockchain ensures that information is tamper-proof and resistant to hacking</div>
                <div>This technology has found applications in finance, supply chain management, healthcare, and many other sectors. Blockchain has the potential to revolutionize how we transfer money, track goods, protect data, and establish trust in various processes. Its decentralized nature and cryptographic security make it a promising solution for enhancing transparency, reducing fraud, and increasing efficiency in our digital world.</div>
              
            </div>
        </div>
    );
};

export default BlocChainBody;